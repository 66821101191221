import React, { useState, useEffect } from 'react'
import InfiniteScroll from 'components/InfiniteScroll'
import LoadingContainer from 'components/LoadingContainer'
import nativo from 'lib/nativo'
import StoryCard from 'components/StoryCard'
import HashtagLabsAd from 'components/HashtagLabsAd'
import { useDetectAdBlock } from 'hooks/detect-adblock'
import { THEME } from 'utils'

function StoryList({
  items = [],
  next = () => {},
  hasMore = false,
  feedType = 'type',
  homepage = false,
  disableNativeAds = false,
  adSettings
}) {
  const loader = () => <LoadingContainer isLoading={true} />
  const [nativoReplaced, setNativoReplaced] = useState(false)
  const [adRendered, setAdRendered] = useState(true)

  useEffect(() => {
    if (disableNativeAds) {
      return
    }
    nativo.subscribe('noad', () => {
      setNativoReplaced(true)
    })
    nativo.start()
  }, [disableNativeAds])

  const adBlockDetected = useDetectAdBlock()

  const getFeedUnit = (idx) => {
    switch (idx + (homepage ? 2 : 1)) {
      // Slot 3
      case 3:
        return !disableNativeAds && <div id='nativeAd-placeholder' />
      // Slot 6
      case 5:
        return (
          !adBlockDetected && (
            <div className='feedAd__slot'>
              <HashtagLabsAd id='leaderboard-in-feed' adSettings={adSettings} setAdRendered={setAdRendered} />
              <style jsx>{`
                .feedAd__slot {
                  text-align: center;
                  overflow-x: hidden;
                  padding: ${adRendered ? '18px 0' : '0'};
                  height: ${adRendered ? 'auto' : '0'};
                  border-bottom: ${adRendered ? '2px solid #f1f1f1' : 'none'};
                }
              `}</style>
            </div>
          )
        )
      // Slot 12
      case 11:
        return <div className='bc-golf-segment-1'></div>
      case 21:
        return <div className='bc-golf-segment-2'></div>
      case 31:
        return <div className='bc-golf-segment-3'></div>
      default:
        return null
    }
  }

  return (
    <InfiniteScroll next={next} hasMore={hasMore} loader={loader}>
      {items.map((story, idx) => (
        <React.Fragment key={story.id}>
          {getFeedUnit(idx)}
          <StoryCard story={story} feedType={feedType} index={idx} />
        </React.Fragment>
      ))}
    </InfiniteScroll>
  )
}

export default StoryList
